  module.exports = {
    contact: {
      title: "Looking to make good use of your health data?",
      description: "Drop us and email anytime, we endeavour to answer all enquiries within 24 hours.",
      addressTitle: "Company Location",
      addressIcon: "fas fa-location-arrow",
      address: "71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ",
      emailTitle: "Email Address",
      emailIcon: "fas fa-envelope",
      email: "sales@agefix.com"
    }
  };
