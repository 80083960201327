import React from "react";

class TeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMember: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="team" className={"team-two-section ptb-100 " + (this.props.isWhite && this.props.isWhite === true ? '' : 'gray-light-bg')}>
            <div className="container">
            {!(this.props.hideTitle && this.props.hideTitle  === true ) && (
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-8">
                        <div className="section-heading text-center">
                            <h2>Our Team Members</h2>
                            <p>We think outside the box and continously strive for excellence in everything we do.</p>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                        <img src="assets/img/team/team-member-1.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                        <div className="team-content">
                            <h5 className="mb-0">Paul Matisin SSWA PM</h5>
                            <span>Founder & CTO</span>
                            <p className="mt-3">Exception experience in managing large scale projects</p>
                            <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                <li className="list-inline-item">
                                    <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="linkedin" href="/#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                        <img src="assets/img/team/team-member-2.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                        <div className="team-content">
                            <h5 className="mb-0">Raluca Matisin PO</h5>
                            <span>Founder & COO / Strategy Planning Director</span>
                            <p className="mt-3">Extensive experience within marketing and events industry.</p>
                            <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                <li className="list-inline-item">
                                    <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="linkedin" href="/#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                        <img src="assets/img/team/team-member-3.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                        <div className="team-content">
                            <h5 className="mb-0">Dr Bogdan Niculae PO</h5>
                            <span>Specialist Medical Advisor</span>
                            <p className="mt-3">Founder of LaserMed and head of many medical congresses.</p>
                            <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                <li className="list-inline-item">
                                    <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="linkedin" href="/#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                        <img src="assets/img/team/team-member-4.png" alt="team" width="120" className="img-fluid m-auto pb-4" />
                        <div className="team-content">
                            <h5 className="mb-0">Dimi Niculae BA</h5>
                            <span>Crypto Advisor & Graphic Designer</span>
                            <p className="mt-3">Many years experience within blockchain projects and graphic design</p>
                            <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                                <li className="list-inline-item">
                                    <a className="facebook" href="/#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="twitter" href="/#" target="_blank"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="dribbble" href="/#" target="_blank"><i className="fab fa-dribbble"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="linkedin" href="/#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                    
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TeamMember;
