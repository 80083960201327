import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="ptb-100 gray-light-bg ">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-12 col-lg-6">
                        <div className="feature-contents section-heading">
                            <h2>Promoting a revolution in medical science</h2>
                            <p>In order to increase the synergy between medical professionals and their patients, we want to decentralise, democratise, and stimulate biotech advancements in medical research. The creation of data science and AI automations and algorithms is facilitated by AgeFix LifePod standard, minimal code, and no-code interfaces.</p>
                            <div className="row align-items-center">
                                {/*<div className="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                                    <div className="img-wrap">
                                        <img src="assets/img/health.png" alt="download" className="img-fluid" />
                                    </div>
                                </div>*/}
                                <div className="col-md-12 col-lg-12">
                                    <div id="accordion" className="accordion faq-wrap">
                                        <div className="card mb-3">
                                            <a className="card-header " data-toggle="collapse" href="#collapse0" aria-expanded="false">
                                                <h6 className="mb-0 d-inline-block">Patients</h6>
                                            </a>
                                            <div id="collapse0" className="collapse show" data-parent="#accordion">
                                                <div className="card-body white-bg">
                                                    <ul className="vertical-list-with-icon py-3">
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-1.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Get access to patients medical records.</strong>
                                                                <p>You may quickly and easily obtain your patients' medical records using AgeFix. Manage care from any location.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-2.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>View current information about your patient's clinical records.</strong>
                                                                <p>With each new LifePod record added, AgeFix gets better, building up a detailed history of your patient’s health journey over time.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-3.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Everything is encrypted and secure</strong>
                                                                <p>With AgeFix, you take charge. You are in total control of what you share, who you share it with, and with what organisations.</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card my-3">
                                            <a className="card-header collapsed" data-toggle="collapse" href="#collapse1" aria-expanded="false">
                                                <h6 className="mb-0 d-inline-block">Services</h6>
                                            </a>
                                            <div id="collapse1" className="collapse " data-parent="#accordion">
                                                <div className="card-body white-bg">
                                                    <ul className="vertical-list-with-icon py-3">
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-4.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Come along as we work to create a world-class medical community on a global scale.</strong>
                                                                <p>AgeFix provides a smooth onboarding process for medical professionals. Any doctor can start providing digital health services and create and validate an account with only a few taps.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-5.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Provide your medical expertise and earn money on your own terms.</strong>
                                                                <p>Include the medical entities you want to use to deliver healthcare services, and take advantage of automated patient billing that may be set up to occur on a one-time or ongoing basis. Choose your own hours, features, and fees for the services.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-6.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Take charge of how you provide your services.</strong>
                                                                <p>A wide range of one-time or ongoing digital health services can be delivered via live chat, live video, or asyncronuos. Additionally, with your patients' prior consent, you will be allowed to discuss your cases among medical boards.</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card my-3">
                                            <a className="card-header collapsed" data-toggle="collapse" href="#collapse2" aria-expanded="false">
                                                <h6 className="mb-0 d-inline-block">Sessions</h6>
                                            </a>
                                            <div id="collapse2" className="collapse " data-parent="#accordion">
                                                <div className="card-body white-bg">
                                                    <ul className="vertical-list-with-icon py-3">
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-7.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Video Consultation</strong>
                                                                <p>Depending on the needs of the treatment, you can plan numerous sessions or just one interactive video consultation. Everything at your earliest convenience.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-8.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Screen Sharing</strong>
                                                                <p>Get all medical information from your patient in real time so that you can do a thorough and precise diagnostic and consultation.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-9.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Asynchronous & Live Chat</strong>
                                                                <p>Depending on how urgent the matter is, you can comment in the thread or have a real-time conversation with the patient.</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mt-3">
                                            <a className="card-header collapsed" data-toggle="collapse" href="#collapse3" aria-expanded="false">
                                                <h6 className="mb-0 d-inline-block">Medical Community Boards</h6>
                                            </a>
                                            <div id="collapse3" className="collapse " data-parent="#accordion">
                                                <div className="card-body white-bg">
                                                    <ul className="vertical-list-with-icon py-3">
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-10.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Create your own Medical Community Board</strong>
                                                                <p>You can work with trusted experts to decide on the best course of action for your patients by discussing medical problems and exchanging knowledge with them using AgeFix.</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-start">
                                                            <div className="mt-2 bg-white shadow-sm rounded p-3 mr-3">
                                                                <img src="assets/img/feature-11.png" width="30" alt="prevention" />
                                                            </div>
                                                            <div className="vertical-list-info">
                                                                <strong>Join other Medical Boards</strong>
                                                                <p>You may participate in any other Medical Board to review difficult cases, provided that you give permission to do so.</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="action-btns mt-3">
                                <a href="https://portal.agefix.com" className="btn btn-brand-02 mr-3">Get Started Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="mask-image">
                            <img src="assets/img/about1.jpg" className="img-fluid" alt="about" />
                            <div className="item-icon video-promo-content">
                                {/*<a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="popup-youtube video-play-icon text-center m-auto"><span className="ti-control-play"></span> </a>*/}
                                <a href="https://player.vimeo.com/video/944365733" className="popup-vimeo video-play-icon text-center m-auto"><span className="ti-control-play"></span> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
